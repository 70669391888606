<script>
import Modal from "../modals/modal-form.vue";
import api from '../../helpers/apirest/api';
import vue2Dropzone from "vue2-dropzone";

import {
    storeComputed
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        activeTab: 1,
        media:[],
        currentMedia: null,
        currentPage: 1,
        perPage: 50,
        totalPages: 0,
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        selected: {
            type: [Array,Object],
            default: () => []
        },
        single: Boolean,
        okButtonLabel: {
            type: String,
            default: 'Añadir'
        }
    },
    data() {
        return {
            ...initialState(),
            dropzoneOptions: {
                url: process.env.VUE_APP_API_URL + 'media',
                method: 'post',
                thumbnailWidth: 150,
                maxFilesize: 3, //MB
                acceptedFiles: 'image/*,application/pdf',
                paramName: 'file',
                // headers: {
                //     'Authorization': 'Bearer tu-token'
                // },
                withCredentials: true,
                addRemoveLinks: true,                
            }
        };
    },
    components: {
        Modal,
        vueDropzone: vue2Dropzone
    },
    computed: {
        selectedIds () {
            if (Array.isArray(this.selected)) {
                return this.selected.map(item => item.id);
            } else if (this.selected && typeof this.selected === 'object') {
                return [this.selected.id];
            }
            return [];
        },
        ...storeComputed,
    },
    methods: {
        async shown() {
            this.getMedia();
            this.$store.dispatch("store/loadList");
        },
        confirm() {
            const selected = this.media.filter(img => img.selected);

            if (this.single) {
                this.$emit("onSuccess", selected[0] || null);
            }else{
                this.$emit("onSuccess", selected);
            }

            this.$bvModal.hide(this.modalId);
        },
        async getMedia() {
            const response = await api.get({
                url: 'media',
                params: {
                    page: this.currentPage,
                    per_page: this.perPage,
                    sort_by: 'updated_at',
                    sort: 'desc',
                    include_ids: this.selectedIds || null
                },
                config: {
                    withLoading: true
                }
            });
            const media = response?.data?.data;
            const selectedIds = this.selectedIds || [];
            media.map(item => {
                item.selected = selectedIds.includes(item.id);
                item.currentSelected = false;
                item.stores.map(store => {
                    store.is_update = true;
                });
                return item
            });

            if(this.currentPage == 1){
                this.media = media;
            }else{
                this.media = this.media.concat(media);
            }

            this.totalPages = response?.data?.meta?.total_pages;
        },
        loadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.getMedia();
            }
        },
        addStore(){
            this.currentMedia.stores.push({
                id: '',
                is_update: false
            });
        },
        deleteStore(key){
            this.currentMedia.stores.splice(key, 1);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        handleComplete() {
            let dropzoneInstance = this.$refs.myVueDropzone.dropzone;
            let fileWithError = 0;
            for (let i = dropzoneInstance.files.length - 1; i >= 0; i--) {
                let file = dropzoneInstance.files[i];
                if (file.status !== 'success') {
                    fileWithError++;
                    break;
                }
            }

            if(fileWithError == 0){
                this.activeTab = 1;
                dropzoneInstance.removeAllFiles();
            }

        },
        uploadDropzoneFinish(file, response) {
            response.data.selected = true;
            response.data.stores = [];
            this.media.unshift(response.data);
        },
        toggleSelection(item) {
            
            this.media.forEach(media => {
                media.currentSelected = false;
            });

            if (this.single) {
                this.media.forEach(media => {
                    if(media.id != item.id) {
                        media.selected = false;                        
                    }
                });
            }

            item.selected = !item.selected;

            if(item.selected) {
                item.currentSelected = true;
                this.currentMedia = item;
            }else{
                this.currentMedia = null;
            }
        },
        async updateMedia() {
            if(!this.currentMedia) return;

            const response = await api.save({
                url: 'media',
                id: this.currentMedia.id,
                data: {
                    name: this.currentMedia.name,
                    alt: this.currentMedia.alt,
                    stores: this.currentMedia.stores
                },
                config: {
                    withLoading: true
                }                
            });
            const data = response?.data?.data;
            const stores = data.stores.map(store => {
                store.is_update = true;
                return store
            });
            this.currentMedia.stores = stores;
        }
    }
};
</script>

<template>
    <Modal :title="'Administrador de medios'" :id="modalId" :label-confirm-btn="okButtonLabel" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="card">
            <div class="card-body">
                <b-tabs content-class="p-3 text-muted" v-model="activeTab">
                    <b-tab class="border-0">
                        <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="ri-upload-cloud-line"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Subir archivos</span>
                        </template>
                        <div>
                            <vue-dropzone
                                id="dropzone"
                                ref="myVueDropzone"
                                :use-custom-slot="true"
                                :options="dropzoneOptions"
                                @vdropzone-queue-complete="handleComplete"
                                @vdropzone-success="uploadDropzoneFinish"
                            >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Arrastre y suelte los archivos aquí o haga clic para subirlos</h4>
                            </div>
                            </vue-dropzone>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="ri-image-2-fill"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Biblioteca de medios</span>
                        </template>
                        <div class="row">
                            <div class="col-9 qlk-preview-container">
                                <div class="qlk-preview-list">
                                    <div
                                        v-for="item in media"
                                        :key="item.id"
                                        class="qlk-preview-item"
                                        @click="toggleSelection(item)"
                                        :class="{ 'selected': item.selected, 'current-selected': item.currentSelected }"
                                    >
                                        <div class="qlk-preview-item-checkbox">
                                            <i
                                                class="ri-checkbox-fill"
                                                :class="{ 'text-secondary': !item.currentSelected , 'text-primary': item.currentSelected }"
                                            ></i>
                                        </div>
                                        <div class="qlk-preview-item-thumbnail">
                                            <img :src="item.url" :alt="item.alt">
                                        </div>
                                    </div>
                                </div>
                                <div class="qlk-preview-pagination text-center mt-2">
                                    <button v-if="currentPage < totalPages" type="button" class="btn btn-icon btn-outline-secondary" @click="loadMore()">Mostrar más</button>
                                    <span v-else class="text-muted">No hay más elementos</span>
                                </div>
                            </div>
                            <div class="col-3 qlk-preview-detail p-3">
                                <div v-if="currentMedia">
                                    <h4 class="card-title">Detalle del adjunto</h4>
                                    <div class="qlk-preview-item">
                                        <div class="qlk-preview-item-thumbnail">
                                            <img :src="currentMedia.url" :alt="currentMedia.alt">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="alt">Texto alternativo</label>
                                        <input
                                            id="alt"
                                            v-model="currentMedia.alt"
                                            type="text"
                                            class="form-control"
                                            placeholder="Déjalo vacío si la imagen es puramente decorativa."
                                            @change="updateMedia()"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="name">Título</label>
                                        <input
                                            id="name"
                                            v-model="currentMedia.name"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa el título del recurso"
                                            @change="updateMedia()"
                                        />
                                    </div>
                                    <div class="card mb-0">
                                        <div class="card-body">
                                            <h4 class="card-title form-inline">
                                                Tiendas
                                            </h4>
                                            <table class="table table-bordered table-sticky-header">
                                                <tbody>
                                                    <tr v-for="(store, i) in currentMedia?.stores" :key="i" class="">
                                                        <td>
                                                            <div class="form-group">
                                                                <select
                                                                    v-model="store.id"
                                                                    class="form-control"
                                                                    @change="updateMedia()"
                                                                    v-if="!store?.is_update"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option v-for="option in stores" v-bind:value="option.id" :key="option.id">
                                                                        {{ option.name }}
                                                                    </option>
                                                                </select> 
                                                                <span v-else>
                                                                    {{ store.name }}
                                                                </span>                                           
                                                            </div>
                                                        </td>                                                    
                                                        <td class="text-center">
                                                            <a
                                                                href="javascript:void(0);"
                                                                class="text-danger"
                                                                v-b-tooltip.hover
                                                                title="Eliminar"
                                                                @click="deleteStore(i)"
                                                                v-if="!store?.is_update"
                                                            >
                                                                <i class="mdi mdi-trash-can font-size-18"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan="2" class="text-right">
                                                            <a
                                                                href="javascript:void(0);"
                                                                class="text-success"
                                                                @click="addStore()"
                                                            >
                                                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar tienda
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table> 
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </b-tab>                    
                </b-tabs>
            </div>
        </div>
    </Modal>
</template>